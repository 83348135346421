import styled from "styled-components"
import { CommonTitle } from "./../Texts"
import { CommonParagraph } from "./../Texts"
import { CommonSubtitle as CommonSubtitleBase } from "./../Texts"

export const Wrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Tab = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  border: 1px solid ${({ theme }) => theme.palette.primary.contrastText};
`

export const Column = styled.div`
  &:first-child {
    border-left: none;
  }
  border-left: 1px solid ${({ theme }) => theme.palette.primary.contrastText};
`

export const Row = styled.div`
  &:first-child {
    border-top: none;
  }
  border-top: 1px solid ${({ theme }) => theme.palette.primary.contrastText};
  padding: 10px 15px;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TabTitle = styled(CommonTitle)`
  width: 90%;
  font-size: 1.2em;
  padding: 0.5em;
  background: #c9c9c9;
  text-align: center;
  cursor: pointer;
`

export const Text = styled(CommonParagraph)`
  margin: 0px;
`

export const Rowline = styled.div``

export const CommonSubtitle = styled(CommonSubtitleBase)`
  font-size: 1em;
  font-weight: normal;
  padding: 0.5em;
`
