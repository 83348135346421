import React from "react"

import {
  Wrapper,
  TitleWrapper,
  Title,
  Paragraph,
  Table,
  RowTable,
  BodyTable,
  TitleTable,
} from "./ProductFeatures.style"
import { buildHtmlFromString } from "../../../../lib/buildHtmlFromString"
import { isEditorjs, convertEditorjsJsonToHtml } from "./../../../../lib/util"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import Draft, { convertFromRaw } from "draft-js"
import { stateToHTML } from "draft-js-export-html"
import { Media } from "gatsby-plugin-fresnel"

interface Props {
  id?: string
  data: string
  className?: string
}

export function ProductFeaturesView({ id = "", data, className }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")
  let html = ""
  try {
    if (isEditorjs(data)) {
      data = data.replace(/\\n/gm, "<br>")
      html = convertEditorjsJsonToHtml(data)
    } else {
      let obj = JSON.parse(data)
      const isHtmlString = obj.blocks.some(block => {
        return Object.keys(block.data).length != 0
      })
      if (isHtmlString) {
        const result = obj.blocks.map(({ data }) => {
          return data?.text
        })
        html = result.join("<br>")
      } else {
        obj.inlineStyleRanges = obj.inlineStyleRanges || []
        obj.entityRanges = obj.entityRanges || []
        obj.entityMap = obj.entityMap || {}
        const result = convertFromRaw(obj)
        html = stateToHTML(result)
      }
    }
  } catch (error) {
    console.warn("Can't format rich text ", error)
  }

  return (
    <Wrapper id={id} className={classNames}>
      <Title className={classNames}>Caractéristiques du produit</Title>
      <Table className={classNames}>
        <Media lessThan="md">
        <TitleTable className={classNames}>Composition / Dimensions / Conseils d'entretien</TitleTable>

          <BodyTable className={classNames}>
            {!isEditorjs(data) && (
              <Paragraph>{buildHtmlFromString(html)}</Paragraph>
            )}
            {isEditorjs(data) && (
              <Paragraph dangerouslySetInnerHTML={{ __html: html }} />
            )}
          </BodyTable>
        </Media>
        <Media greaterThanOrEqual="md">
          <RowTable>
            <TitleTable>Composition / Dimensions / Conseils d'entretien</TitleTable>
            <BodyTable>
              {!isEditorjs(data) && (
                <Paragraph>{buildHtmlFromString(html)}</Paragraph>
              )}
              {isEditorjs(data) && (
                <Paragraph dangerouslySetInnerHTML={{ __html: html }} />
              )}
            </BodyTable>
          </RowTable>
        </Media>
      </Table>
    </Wrapper>
  )
}
