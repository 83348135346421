import styled from "styled-components"
import { ProductSelection as ProductSelectionBase } from "./ProductSelection"
import { Mosaic as MosaicBase } from "../../common/Mosaic"
import { Button } from "../../common/Button"

export const Wrapper = styled.div``

export const Row = styled.div``

export const Grid = styled(Row)`
  max-width: 1250px;
  width: 95%;
  margin: 3em auto 0 auto;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 2em;
`

export const WrapperInfos = styled.div`
  width: 90%;
  max-width: 1250px;
  margin: auto;
`

export const ProductSelection = styled(ProductSelectionBase)`
  box-sizing: border-box;
`

export const Mosaic = styled(MosaicBase)``

export const Line = styled.hr`
  width: 100%;
  color: #2b3944;
  opacity: 0.22;
`

export const WrapperChild = styled.div`
  width: 500px;
  &.mobile{
    margin: auto;
    max-width: 95%;
  }
`

export const DescriptionEncart = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  padding: 2em 0;
  // margin-bottom: 100px;
  &.mobile {
    display: flex;
    flex-direction: column;
  }
`
export const Descriptionhr = styled.hr`
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
  background-color: RGB(189, 157, 109);
  height: 0.5px;
  &.mobile {
    margin-left: 2%;
    margin-right: 2%;
    height: 0.5px;
    width: 90%;
  }
`
export const ButtonCompose = styled(Button)`
  padding: 0.75em 20px;
  margin: 40px auto;
  &.mobile {
    margin: 20px auto;
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  max-width: 1250px;
  width:100%;
  margin: 50px auto 0 auto;
  border-top: 2px solid #d9d9d9;
  &.mobile {
    width: 95%;
    margin: auto;
  }
`

export const WrapperComposable = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1400px;
  &.mobile {
    width: 95%;
    margin: auto;
  }
`
