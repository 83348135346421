import React from "react"
import {Info, Img, Flex, Column, Text } from "./BuyingInfos.style"
import { useDetectDevice } from "./../../../lib/useDetectDevice"
import { useInfoBarQuery } from "../InfoBar/InfoBar.query"

interface Props {
  className?: string
  isComposable: boolean
}

export function BuyingInfosView({ className, isComposable }: Props) {
  const data = useInfoBarQuery()
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")
  if(isComposable){
    return (
      <Column className={classNames}>
      <Info className={classNames}>
        <Img data={data.strapiGmHeader.BandeauReassurance.Image1} />
        <Text>{data.strapiGmHeader.BandeauReassurance.Text1}</Text>
      </Info>
      <Info className={classNames}>
        <Img data={data.strapiGmHeader.BandeauReassurance.Image2} />
        <Text>{data.strapiGmHeader.BandeauReassurance.Text2}</Text>
      </Info>
      <Info className={classNames}>
        <Img data={data.strapiGmHeader.BandeauReassurance.Image3} />
        <Text>{data.strapiGmHeader.BandeauReassurance.Text3}</Text>
      </Info>
    </Column>
    )
  }
  return (
    <Flex className={classNames}>
      <Info className={classNames}>
        <Img data={data.strapiGmHeader.BandeauReassurance.Image1} />
        <Text>{data.strapiGmHeader.BandeauReassurance.Text1}</Text>
      </Info>
      <Info className={classNames}>
        <Img data={data.strapiGmHeader.BandeauReassurance.Image2} />
        <Text>{data.strapiGmHeader.BandeauReassurance.Text2}</Text>
      </Info>
      <Info className={classNames}>
        <Img data={data.strapiGmHeader.BandeauReassurance.Image3} />
        <Text>{data.strapiGmHeader.BandeauReassurance.Text3}</Text>
      </Info>
    </Flex>
  )
}
