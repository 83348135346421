import React, { useEffect, useState } from "react"
import { Link } from "../Link"
import { BreadcrumbsSeparator, Wrapper } from "./BreadcrumbsBox.style"

interface props {
    category: { name?: string, level: number, slug?: string, parent?: { name?: string, slug?: string, parent?: { name?: string, slug?: string, } } },
    productName?: string
    setContext: ({ }) => void
}

export const BreadcrumbsBoxView = ({ category, productName, setContext }: props) => {

    const [context, setCrumbsContext] = useState({})

    const buildContext = (level) => {

        const { slug: categorySlug, name: categoryName, parent } = category || {}
        const { slug: parentSlug, name: parentName, parent: parentParent } = parent || {}
        const { slug: parentParentSlug, name: parentParentName } = parentParent || {}
        

        return { categorySlug, categoryName, parentSlug, parentName, parentParentSlug, parentParentName, level: category.level }
    }

    useEffect(() => {
        if (category) {
            const data = { ...buildContext(category) }
            setContext(data)
            setCrumbsContext(data)
        }


    }, [category,])

    if(productName==context.categoryName){
        return(
            <Wrapper>
            <Link color="inherit" link="/">
                Accueil
            </Link>
            <Item slug={"/" + context.parentParentSlug} name={context.parentParentName} />
            <Item slug={"/" + context.parentSlug} name={context.parentName} />
            {productName && (
                <>
                    <BreadcrumbsSeparator color="textPrimary">/</BreadcrumbsSeparator>
                    <BreadcrumbsSeparator color="textPrimary">
                        {productName}
                    </BreadcrumbsSeparator>
                </>
            )}
        </Wrapper>
    )
    }
    return (
        <Wrapper>
            <Link color="inherit" link="/">
                Accueil
            </Link>
            <Item slug={"/" + context.parentParentSlug} name={context.parentParentName} />
            <Item slug={"/" + context.parentSlug} name={context.parentName} />
            <Item slug={"/" + context.categorySlug} name={context.categoryName} />
            {productName && (
                <>
                    <BreadcrumbsSeparator color="textPrimary">/</BreadcrumbsSeparator>
                    <BreadcrumbsSeparator color="textPrimary">
                        {productName}
                    </BreadcrumbsSeparator>
                </>
            )}
        </Wrapper>
    )
}
const Item = ({ name, slug }) => {
    if (!name) return <></>

    return (<>
        <BreadcrumbsSeparator color="textPrimary">
            /
        </BreadcrumbsSeparator>
        <Link link={slug}>
            {name}
        </Link>
    </>)
}

