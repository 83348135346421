import styled from "styled-components"
import { Button as ButtonBase } from "./../../../common/Button"
import { CommonParagraph } from "../../../common/Texts"
import { ImageWithLinkOrNot } from "../../../common/ImageWithLinkOrNot"

export const Wrapper = styled.div`
  // padding: 1.5em 2em;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
  &.mobile {
    width: auto;
    margin-bottom: 1em;
    padding: 0 2em 1em 2em;
  }
`

export const Separation = styled.div``

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TextRow = styled(CommonParagraph)`
  margin: 0;
  font-weight: bold;
  padding: 1.5% 0;
`

export const Text = styled(CommonParagraph)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  height: auto;
  margin: 0;
  padding: 0 15px;
`

export const TextProductPrice = styled(Text)`
  font-size: 1.5rem;
  white-space: nowrap;
`

export const TextTotalPrice = styled(Text)`
  white-space: nowrap;
`

export const Button = styled(ButtonBase)`
  width: fit-content;
  grid-column: 2;
  &.mobile {
    grid-column: 1;
  }
`

export const RowComposition = styled.div`
  padding: 2em 0;
  display: grid;
  align-items: center;
  grid-template-columns: 15% 50% 35%;
  &.mobile {
    grid-template-columns: 1fr 1fr;
  }
`

export const Composition = styled.div`
  grid-column: 3;
  display: flex;
  &.mobile {
    grid-column: 2;
  }
`

export const ProductFromSet = styled.div``
export const Bottom = styled.div`
  padding: 1em 0;
`

export const Header = styled.div`
  height: auto;
  font-weight: bold;
  &.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  box-sizing: border-box;
`

export const Top1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
`

export const BrandRow = styled.div`
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: 1.35em;
  opacity: 0.3;
  text-transform: uppercase;
  &.mobile {
    display: flex;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`
export const ProductRow = styled.div`
  font-size: 1.5em;
  &.mobile {
    display: flex;
    margin: 0;
    padding: 0;
  }
`

export const ImageLogo = styled(ImageWithLinkOrNot)`
  object-fit: contain;
  width: auto;
  display: inline-flex;
  float: left;
  max-height: 6em;
  max-width: 7em;
  box-shadow: ${({ theme }) => theme.shadows[2]};
`

export const Exp = styled.div`
  &.mobile {
    display: none;
  }
`
export const Size = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 15px;
`
