import { gql } from "@apollo/client"

export const GET_MODEL_METADATA_FROM_SLUG = gql`
  query getModelMetadata($slug: String) {
    product(slug: $slug) {
      seoTitle
      seoDescription
      metadata {
        key
        value
      }
    }
  }
`

