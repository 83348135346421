import React, { useEffect, useRef, useState } from "react"
import { SEO } from "../../common/SEO"
import { Layout } from "../../common/Layout"
import { Media } from "gatsby-plugin-fresnel"
import {
  Wrapper,
  Grid,
  ProductSelection,
  Mosaic,
  Line,
  WrapperChild,
  DescriptionEncart,
  WrapperInfos,
  ButtonCompose,
  WrapperComposable,
  FlexWrapper,
} from "./Product.style"

import { ProductFeatures } from "./ProductFeatures"
import { RelatedProducts } from "./RelatedProducts"
import { ProductSet } from "./ProductSet"
import { useMarquesImageQuery, GET_COMPOSITION_SKUS } from "./Product.query"
import { getMetadata } from "./../../../utils"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { useBreadcrumbsBox } from "../../common/Breadcrumbs"
import { useDispatch } from "react-redux"
import { setAnchorListItem } from "../../../entities/anchorListitem/anchorListItem.actions"
import getBrandFromSaleor from "../../../helper/getBrandFromSaleor"
import slugify from "slugify"
import { ProductDescription } from "./ProductDescription"
import { Composable } from "./ProductSelection/Composable"
import { navigate } from "gatsby"
import { isDomAvailable } from "../../../lib"
import { useQuery } from "@apollo/client"
import { BuyingInfos } from "../../common/BuyingInfos"

interface Props {
  pageContext: any
  data: any
}

function GetCompositionSkus(modelSlug: string) {
  const skus = useRef(null)

  const { loading, error, data } = useQuery(GET_COMPOSITION_SKUS, {
    variables: { slug: modelSlug },
  })

  if ((!error && !loading) || data?.product?.metadata) {
    const meta = getMetadata(data?.product?.metadata) || {}
    return meta["zoho_composition"]
  }

  return []
}

export function ProductView({ data, pageContext }: Props) {
  data = { ...data.saleor.product, ...pageContext.data }

  const meta = getMetadata(data.metadata)
  const modelSlug = meta["model_id"]

  const isMobile = useDetectDevice()
  const [variant, setVariant] = useState(data.defaultVariant)
  const [variantMetadata, setVariantMetadata] = useState(
    getMetadata(data.defaultVariant.metadata)
  )
  const metadata = getMetadata(data.metadata)
  const dispatch = useDispatch()
  const getAllBrandsLogo = () => {
    const data = useMarquesImageQuery()
    var res = []
    data.allStrapiGmPagesMarque.edges.map(({ node }) => {
      const brandSlug = slugify(node.NomMarque, { lower: true }).replace("\'","")
      res[brandSlug] = node.Logo
    })
    return res
  }

  useEffect(() => {
    if (data?.slug) {
      dispatch(setAnchorListItem(data.slug))
    }
  }, [data])

  const logos = getAllBrandsLogo()

  const getMeta = name => {
    if (variantMetadata[name]) return variantMetadata[name]
    else if (metadata[name]) return metadata[name]
    else return ""
  }

  const brand = getBrandFromSaleor(data.attributes) || getMeta("zoho_brand")
  const brandslug = slugify(brand, { lower: true }).replace("\'","")
  const brandLogo = { media: logos[brandslug] }

  const updateSelectedVariant = variant => {
    setVariant(variant)
    const variantMetadata = getMetadata(variant.metadata)
    setVariantMetadata(variantMetadata)
  }

  const previousPageName = data?.category?.name
  const previousPageSlug = "/" + data?.category?.slug
  const isProductSet = data.productType.name == "Parure"

  var skus = []
  if (isProductSet == false) {
    skus = GetCompositionSkus(modelSlug)
  }

  const navigateToComposition = () => {
    if (isDomAvailable()) {
      let current = window.location.pathname.split("/")
      if (current[current.length - 1] == "") {
        current.pop()
      }
      current.pop()
      current.push(modelSlug)
      let path = current.join("/")
      navigate(path)
    }
  }

  const [BreadcrumbsBox, NavigateBackButton] = useBreadcrumbsBox({
    category: data?.category,
    productName: data?.name,
  })
  return (
    <Layout>
      <SEO title={data.seoTitle} description={data.seoDescription} />
      <Wrapper>
        <Media lessThan="md">
          <Mosaic media={data.media} />
          <GetChildren
            dataChild={data}
            variant={variant}
            updateSelectedVariant={updateSelectedVariant}
            brandLogo={brandLogo}
            brandName={getMeta("zoho_brand")}
            isProductSet={isProductSet}
            isMobile={isMobile}
          ></GetChildren>
          {skus && skus.length > 0 && (
            <FlexWrapper className={isMobile && "mobile"}>
              <WrapperComposable className={isMobile && "mobile"}>
                <Composable skus={skus} />
                {modelSlug && (
                  <ButtonCompose
                    onClick={navigateToComposition}
                    className={isMobile && "mobile"}
                  >
                    Composer votre parure
                  </ButtonCompose>
                )}
              </WrapperComposable>
            </FlexWrapper>
          )}
        </Media>
        <Media greaterThanOrEqual="md">
          <BreadcrumbsBox />
          <Grid>
            <Mosaic media={data.media} />
            <GetChildren
              dataChild={data}
              variant={variant}
              updateSelectedVariant={updateSelectedVariant}
              brandLogo={brandLogo}
              brandName={getMeta("zoho_brand")}
              isProductSet={isProductSet}
              isMobile={isMobile}
            ></GetChildren>
          </Grid>
          {skus.length > 0 && (
            <FlexWrapper>
              <WrapperComposable>
                <Composable skus={skus} />
                {modelSlug && (
                  <ButtonCompose
                    onClick={navigateToComposition}
                    className={isMobile && "mobile"}
                  >
                    Composer votre parure
                  </ButtonCompose>
                )}
              </WrapperComposable>
              <BuyingInfos isComposable={true} />
            </FlexWrapper>
          )}
          {skus.length == 0 && (
            <FlexWrapper>
              <BuyingInfos isComposable={false} />
            </FlexWrapper>
          )}
        </Media>

        <DescriptionEncart className={isMobile && "mobile"}>
          <WrapperInfos>
            <ProductDescription id="description" data={data.description} />
            <Line />
            <ProductFeatures
              id="caracteristiques"
              data={getMeta("zoho_carac")}
            />
          </WrapperInfos>
        </DescriptionEncart>

        <RelatedProducts skus={getMeta("zoho_suggest")} brandsLogo={logos} />
        <NavigateBackButton />
      </Wrapper>
    </Layout>
  )
}

const GetChildren = ({
  dataChild,
  variant,
  updateSelectedVariant,
  brandLogo,
  brandName,
  isProductSet,
  isMobile,
}) => {
  const skus = useRef(null)

  const meta = getMetadata(dataChild.metadata)
  const modelSlug = meta["model_id"]

  const { loading, error, data } = useQuery(GET_COMPOSITION_SKUS, {
    variables: { slug: modelSlug },
  })

  if ((!error && !loading) || data?.product?.metadata) {
    const meta = getMetadata(data?.product?.metadata) || {}
    skus.current = meta["zoho_composition"]
  }

  const productName = dataChild.name

  if (isProductSet) {
    return (
      <WrapperChild className={isMobile && "mobile"}>
        <ProductSet
          brandName={brandName}
          productName={productName}
          brandLogo={brandLogo.media}
          skus={meta["zoho_composition"]}
        />
      </WrapperChild>
    )
  }

  return (
    <WrapperChild className={isMobile && "mobile"}>
      <ProductSelection
        brandName={brandName}
        productName={productName}
        brandLogo={brandLogo.media}
        data={dataChild}
        variant={variant}
        setVariant={updateSelectedVariant}
      />
    </WrapperChild>
  )
}
