import styled from "styled-components"
import { CommonSubtitle } from "../../../common/Texts"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2em 0;
  font-family: ${props => props.theme.typography.body.fontFamily};
  font-weight: 300;
  line-height: ${props => props.theme.typography.body.lineHeight};
  letter-spacing: ${props => props.theme.typography.body.letterSpacing};
  color: ${props => props.theme.typography.color};
  margin: 0 0 15px 0;
  &.mobile {
    margin: auto;
  }
`

export const Title = styled(CommonSubtitle)`
  font: normal normal 600 30px/22px Cormorant Garamond;
  text-align: left;
  padding: 0 0 50px 0;
  &.mobile {
    font-size: 2em;
  }
`

export const Paragraph = styled.div`
  & p {
    margin: 0;
  }
`

export const Table = styled.div`
  width: 850px;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0000000D;
  &.mobile {
    width: 100%;
  }
`
export const RowTable = styled.div`
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  :last-child {
    border-bottom: none;
  }
`
export const TitleTable = styled.div`
  width: 150px;
  padding: 20px;
  padding-left: 45px;
  font-weight: 600;
  border-right: 1px solid #d9d9d9;
  :last-child {
    border-right: none;
  }
  &.mobile {
    border: none;
    width: auto;
    padding: 20px 0 0 20px ;
  }
`
export const BodyTable = styled.div`
  width: 700px;
  padding: 20px;
  &.mobile {
    border-bottom: 1px solid #d9d9d9;
    width: auto;
  }
`
