import styled from "styled-components"
import { ImageWithLinkOrNot } from "../ImageWithLinkOrNot"
import { Icon } from "../Icon"

export const GridPreviewImage = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  max-height: 580px;
  &.mobile {
    display: flex;
    flex-direction: column-reverse;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  max-height: inherit;
  &.mobile {
    display: flex;
    flex-direction: row;
  }
`

export const ColumnPreview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  width: 112px;
  height: auto;
  max-height: inherit;
  &.mobile {
    display: flex;
    flex-direction: row;
    width: auto;
  }
`

export const Image = styled(ImageWithLinkOrNot)`
  object-fit: contain;
  max-height: 580px;
  min-height: 300px;
  max-width: 580px;
  min-width: 300px;
  &.mobile {
    height: 95%;
    width: 95%;
    max-height: auto;
    max-width: auto;
    min-width: auto;
    min-height: auto;
    margin: auto;
  }

`

export const Thumbnail = styled(ImageWithLinkOrNot)`
  width: 100px;
  height: 100px;
  object-fit: cover;
`
export const SamplePrevArrow = styled(Icon)`
  height: 200px;
  margin: 0 auto;
  fill: black;
  &.mobile {
    transform: rotate(-90deg);
    margin: auto;
  }
`
export const SampleNextArrow = styled(Icon)`
  height: 20px;
  transform: rotate(180deg);
  margin: 0 auto;
  &.mobile {
    transform: rotate(90deg);
    margin: auto;
  }
`
