import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import {
  Wrapper,
  Select,
  StyledCounter,
  Row,
  Image,
  Text,
  TextProduct,
  TextPrice,
  ProductInfos,
  SelectProduct,
  OldPrice,
  NewPrice,
} from "./Article.style"
import { Link } from "./../../../../common/Link"
import { getProductUrl } from "./../../../../../utils/metadata"
import { useDetectDevice } from "../../../../../lib/useDetectDevice"
import { fixPrice } from "../../../../../helper/fixedPrice"

const getPricingNode = pricing => {
  let price = pricing.price.gross.amount
  let priceUndiscounted = pricing.priceUndiscounted?.gross.amount
  let currency = pricing.price.gross.currency
  if (pricing.onSale) {
    return (
      <Row>
        <OldPrice>
          {fixPrice(priceUndiscounted)}
          {currency === "Eur" ? "€" : currency}
        </OldPrice>
        <NewPrice>
          {fixPrice(price)}
          {currency === "Eur" ? "€" : currency}
        </NewPrice>
      </Row>
    )
  }
  return (
    <div>
      {fixPrice(price)}
      {currency === "Eur" ? "€" : currency}
    </div>
  )
}

const getVariantWithImage = (product, theVariant) => {
  var res = JSON.parse(JSON.stringify(theVariant))
  if (res?.media?.length == 0) {
    res.media = product.media
  }
  return res
}

const constructAttributesFromData = data => {
  var res = {}
  data.map(item => {
    var variantId = item.id
    item.attributes.map(attributesNode => {
      if (attributesNode.attribute) {
        var attrName = attributesNode.attribute.name
        if (!res[attrName]) {
          res[attrName] = {}
        }
        attributesNode.values.map(valuesNode => {
          let valueName = valuesNode.name
          if (!res[attrName][valueName]) {
            res[attrName][valueName] = []
          }
          res[attrName][valueName].push(variantId)
        })
      }
    })
  })
  return res
}

const getDefaultSelectors = variant => {
  var res = {}
  variant.attributes.map(item => {
    var name = item?.attribute?.name
    var value = item?.values[0]?.name
    if (value) {
      res[name] = value
    }
  })
  return res
}

interface Props {
  product: Record<string, any>[]
  selectedVariant: Record<string, any>
  quantity: Number
  updateVariant: Function
  updateQuantity: Function
  className?: String
}

export function ArticleView({
  product,
  selectedVariant,
  quantity,
  updateVariant,
  updateQuantity,
  className,
}: Props) {
  const isMobile = useDetectDevice()
  const [isVariantUnvalable, setIsVariantUnvalable] = useState(false)
  const attributes = constructAttributesFromData(product.variants)
  const activeSelectors = getDefaultSelectors(selectedVariant)
  let classNames = className ? className : ""
  if (isMobile) classNames = classNames + " mobile"

  const updateVariantWithNewSelectors = selectors => {
    const result = product.variants.map(variantItem => {
      var isTheOne = true
      variantItem.attributes.map(item => {
        var name = item?.attribute?.name || ""
        var value = item?.values[0]?.name || ""
        if (selectors.hasOwnProperty(name)) {
          if (selectors[name] != value) {
            isTheOne = false
          }
        }
      })
      if (isTheOne) {
        let currentSelection = getVariantWithImage(product, variantItem)
        var article = {
          name: product.name,
          slug: product.slug,
          variant: currentSelection,
        }
        updateVariant(product.slug, article)
      }
    })
    const finded = result.some(x => x == true)
    finded ? setIsVariantUnvalable(false) : setIsVariantUnvalable(true)
  }

  const handleVariantChange = (e: any) => {
    let name = e.target.name
    let value = e.target.value
    let res = { ...activeSelectors }
    res[name] = value
    updateVariantWithNewSelectors(res)
  }
  const classNamesUnv =
    (isMobile ? "mobile" : "") +
    " " +
    (isVariantUnvalable ? "isvariantunvalable" : "")

  return (
    <Wrapper className={classNames}>
      <ProductInfos className={isMobile && "mobile"}>
        <Link link={getProductUrl(product)}>
          <Image data={product.thumbnail} />
        </Link>
        <TextProduct className={isMobile && "mobile"}>
          {product.name}
        </TextProduct>
      </ProductInfos>
      <SelectProduct className={classNamesUnv}>
        {Object.keys(attributes).map(name => (
          <div key={uuidv4()}>
            <Select
              name={name}
              defaultValue={activeSelectors[name]}
              className={isMobile && "mobile"}
              onChange={handleVariantChange}
            >
              {Object.keys(attributes[name]).map(value => {
                return (
                  <option key={uuidv4()} value={value}>
                    {value}
                  </option>
                )
              })}
            </Select>
          </div>
        ))}

        {isVariantUnvalable && <TextPrice>Non disponible</TextPrice>}
        {!isVariantUnvalable && (
          <>
            <TextPrice className={isMobile && "mobile"}>
              {getPricingNode(selectedVariant.pricing)}
            </TextPrice>
            <StyledCounter
              onChange={(count: number) => updateQuantity(product.slug, count)}
              value={quantity}
            />
          </>
        )}
      </SelectProduct>
    </Wrapper>
  )
}
