import React, { useState } from "react"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { SizeLinge } from "./SizeLinge"
import { SizeCouette } from "./SizeCouette"
import { SizeCouverture } from "./SizeCouverture"
import { SizeCouvreLit } from "./SizeCouvreLit"

import {
  Wrapper,
  Icon,
  Button,
  Popin,
  CommonTitle,
  CommonSubtitle,
  BtnSelectInfo,
  SelectChoice,
  SelectProducts,
} from "./SizeGuide.style"

interface Props {
  className?: string
}

export function SizeGuideView({ className }: Props) {
  const [display, setDisplay] = useState(false)
  const toggleDisplay = () => {
    setDisplay(!display)
  }

  const types = ["Linge de Lit", "Couette", "Couvre Lit", "Couverture"]
  const [active, setActive] = useState(types[0])
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")

  return (
    <Wrapper className={classNames}>
      {/* <Icon icon="th-list" onClick={toggleDisplay} /> */}
      <Button onClick={toggleDisplay} className={isMobile && "mobile"}>(Guide des tailles)</Button>
      {display && (
        <Popin
          onClickOutside={toggleDisplay}
          title="Guide des Tailles"
          width={isMobile ? "90%" : "40%"}
          minWidth={!isMobile ? "600px" : ""}
          height={!isMobile ? "" : "100%"}
        >
          {/* <CommonTitle>Guide des Tailles</CommonTitle> */}
          <CommonSubtitle className={isMobile && "mobile"}>
            Veuillez noter que les dimensions ci-dessous sont données à titre
            indicatif seulement.
          </CommonSubtitle>

          <SelectProducts className={isMobile && "mobile"}>
            {types.map(type => {
              let classes = classNames + " " + (active === type && "active")
              return (
                <BtnSelectInfo
                  key={type}
                  className={classes}
                  onClick={() => setActive(type)}
                >
                  {type}
                </BtnSelectInfo>
              )
            })}
          </SelectProducts>
          <SelectChoice>
            {active == "Linge de Lit" && <SizeLinge />}
            {active == "Couette" && <SizeCouette />}
            {active == "Couvre Lit" && <SizeCouvreLit />}
            {active == "Couverture" && <SizeCouverture />}
          </SelectChoice>
        </Popin>
      )}
    </Wrapper>
  )
}
