import styled from "styled-components"
import { ImageWithLinkOrNot } from "../../../../common/ImageWithLinkOrNot"
import { Slider as SliderBase } from "../../../../common/Slider"
import { CommonParagraph } from "../../../../common/Texts"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  max-width: 40vw;
  width: 600px;
  &.mobile {
    margin: 0;
    max-width: none;
  }
`
export const Flex = styled.div`
  display: flex;
  max-width: 965px;
  overflow: auto;
  padding-bottom: 10px;
  gap: 15px;
  border-right: 2px solid #d9d9d9;
  &.mobile {
    width: 100%;
    flex-direction: column;
    border-right: none;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.mobile {
    border-bottom: 1px solid #d2d2d2;
    flex-direction: row;
    padding-bottom: 10px;
    gap: 20px;
    :last-child {
      border-bottom: none;
    }
  }
`

export const Text = styled(CommonParagraph)`
  margin: 0;
  font: normal normal 600 30px/22px Cormorant Garamond;
  padding: 45px 0 30px 0;
`
export const TextThumbnail = styled(CommonParagraph)`
  margin: 0;
  width: 95%;
  font-size: 13px;
  padding: 1.5% 0;
  &.mobile {
    font-size: 15px;
    margin: 0 0 auto 0;
  }
`

export const Slider = styled(SliderBase)`
  &.mobile {
    display: none;
  }
`
export const Thumbnail = styled(ImageWithLinkOrNot)`
  width: 225px;
  height: 225px;
  border: 1px solid #d2d2d2;
  object-fit: contain;
  &.mobile {
    width: 125px;
    height: 125px;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  &.mobile{
    width: 100%;
  }
`

export const Line = styled.div`
  width: 33px;
  height: 0px;
  border: 1px solid #d8d8d8;
  opacity: 1;
`
