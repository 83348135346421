import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

export const SuggestArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  &.mobile {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`

export const TitleWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-content: center;
`
