import React, { useState } from "react"

import {
  Wrapper,
  Tab,
  Column,
  Row,
  Text,
  TabTitle,
  CommonSubtitle,
} from "./../SizeGuideProduct.style"

interface Props {
  className?: string
}

export function SizeLingeView({ className }: Props) {
  const [active, setActive] = useState("M90")
  const toggleActive = (id: string) => {
    active == id ? setActive("") : setActive(id)
  }

  return (
    <Wrapper className={className}>
      <CommonSubtitle>
        CHOISIR LA BONNE TAILLE POUR SON LINGE DE LIT
      </CommonSubtitle>
      <TabTitle onClick={() => toggleActive("M90")}>
        MATELAS 1 personne 90x190 ou 90x200
      </TabTitle>
      {active == "M90" && (
        <Tab>
          <Column>
            <Row>Housse de Couette</Row>
            <Row>
              <Text>140 x 200 cm </Text>
            </Row>
          </Column>
          <Column>
            <Row>Drap Plat</Row>
            <Row>180 x 290 cm</Row>
          </Column>
          <Column>
            <Row>Drap Housse</Row>
            <Row>
              <Text>90 x 190 cm</Text>
              <Text> ou </Text>
              <Text>90 x 200 cm</Text>
            </Row>
          </Column>
        </Tab>
      )}
      <TabTitle onClick={() => toggleActive("M120")}>
        MATELAS 1 à 2 personnes 120x190 ou 120x200
      </TabTitle>
      {active == "M120" && (
        <Tab>
          <Column>
            <Row>Housse de Couette</Row>
            <Row>200 x 200 cm</Row>
          </Column>
          <Column>
            <Row>Drap Plat</Row>
            <Row>240 x 300 cm</Row>
          </Column>
          <Column>
            <Row>Drap Housse</Row>
            <Row>
              <Text>120 x 190 cm</Text>
              <Text> ou </Text>
              <Text>120 x 200 cm</Text>
            </Row>
          </Column>
        </Tab>
      )}
      <TabTitle onClick={() => toggleActive("M140")}>
        MATELAS 2 personnes 140x190 ou 140x200
      </TabTitle>
      {active == "M140" && (
        <Tab>
          <Column>
            <Row>Housse de Couette</Row>
            <Row>240 x 220 cm</Row>
          </Column>
          <Column>
            <Row>Drap Plat</Row>
            <Row>240 x 300 cm</Row>
          </Column>
          <Column>
            <Row>Drap Housse</Row>
            <Row>
              <Text>140 x 190 cm</Text>
              <Text> ou </Text>
              <Text>140 x 200 cm</Text>
            </Row>
          </Column>
        </Tab>
      )}
      <TabTitle onClick={() => toggleActive("M160")}>
        MATELAS 2 personnes 160x200
      </TabTitle>
      {active == "M160" && (
        <Tab>
          <Column>
            <Row>Housse de Couette</Row>
            <Row>260 x 240 cm</Row>
          </Column>
          <Column>
            <Row>Drap Plat</Row>
            <Row>270 x 300 cm</Row>
          </Column>
          <Column>
            <Row>Drap Housse</Row>
            <Row>
              <Text>160 x 200 cm</Text>
            </Row>
          </Column>
        </Tab>
      )}
      <TabTitle onClick={() => toggleActive("M180")}>
        MATELAS 2 personnes 180x200
      </TabTitle>
      {active == "M180" && (
        <Tab>
          <Column>
            <Row>Housse de Couette</Row>
            <Row>260 x 240 cm</Row>
          </Column>
          <Column>
            <Row>Drap Plat</Row>
            <Row>270 x 300 cm</Row>
          </Column>
          <Column>
            <Row>Drap Housse</Row>
            <Row>
              <Text>180 x 200 cm</Text>
            </Row>
          </Column>
        </Tab>
      )}
      <TabTitle onClick={() => toggleActive("M200")}>
        MATELAS 2 personnes 200x200
      </TabTitle>
      {active == "M200" && (
        <Tab>
          <Column>
            <Row>Housse de Couette</Row>
            <Row>280 x 240 cm</Row>
          </Column>
          <Column>
            <Row>Drap Plat</Row>
            <Row>270 x 300 cm</Row>
          </Column>
          <Column>
            <Row>Drap Housse</Row>
            <Row>
              <Text>200 x 200 cm</Text>
            </Row>
          </Column>
        </Tab>
      )}
    </Wrapper>
  )
}
