import { gql } from "@apollo/client"


export const GET_CATEGORY_ATTACH_FROM_COLLECTION = gql`
query getCategoryAttach($categoryId: ID) {
  category(id: $categoryId) {
    name
    slug
    level
    parent {
      name
      slug
      parent {
        slug
        name
      }
    }
  }
}

`