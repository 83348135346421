import * as React from 'react'
import { graphql } from 'gatsby'
import { ProductView as Product } from "./../components/specific/TemplateProduct/Product.view"

/****
 * Template de generation des produit ou des parrure
 */

export default Product


export const query = graphql`fragment fragPricing on SALEOR_VariantPricingInfo {
    onSale
    discount {
      currency
      gross {
        amount
      }
      tax {
        amount
      }
      net {
        amount
      }
    }
    priceUndiscounted {
      currency
      gross {
        amount
      }
      tax {
        amount
      }
      net {
        amount
      }
    }
    price {
      currency
      gross {
        amount
      }
      tax {
        amount
      }
      net {
        amount
      }
    }
  }
  
  fragment fragCategory on SALEOR_Category {
    id
    name
    slug
    level
    parent {
      id
      name
      slug
      level
      parent {
        id
        name
        slug
        level
      }
    }
  }
  
  fragment fragVariant on SALEOR_ProductVariant {
    name
    id
    sku
    media {
      url
      alt
    }
    pricing {
      ...fragPricing
    }
    attributes {
      attribute {
        id
        name
      }
      values {
        name
        id
      }
    }
    metadata {
      key
      value
    }
    quantityAvailable
  }
  
  query productQuery($slug: String) {
    saleor {
      product(slug: $slug) {
        media {
          url
          alt
        }
        attributes {
          attribute {
            slug
          }
          values {
            slug
          }
        }
        seoTitle
        seoDescription
        category {
          ...fragCategory
        }
        description
        name
        slug
        metadata {
          key
          value
        }
        seoTitle
        seoDescription
        isAvailable
        availableForPurchase
        isAvailableForPurchase
        defaultVariant {
          ...fragVariant
        }
        productType {
          name
          variantAttributes {
            name
          }
        }
        variants {
          ...fragVariant
        }
      }
    }
  }
  `