import React, { useState } from "react"
import {
  GridPreviewImage,
  ColumnPreview,
  Thumbnail,
  Image,
  SamplePrevArrow,
  SampleNextArrow,
  Column,
} from "./Mosaic.style"
import { v4 as uuidv4 } from "uuid"
import { useDetectDevice } from "../../../lib/useDetectDevice"

interface Props {
  className?: string
  media: Record<string, any>
}

export function MosaicView({ media, className }: Props) {
  const [currentImage, setCurrentImage] = useState(media[0])

  const handleSlideChange = (item: Record<string, any>) => {
    setCurrentImage(item)
  }
  const isMobile = useDetectDevice()


  return (
    <GridPreviewImage className={isMobile && "mobile"}>
      <Column className={isMobile && "mobile"}>
        <SamplePrevArrow icon="chevron-up" color="#BC9B6B" size="24" className={isMobile && "mobile"}/>
        <ColumnPreview className={isMobile && "mobile"}>
          {media.map(image => (
            <Thumbnail
              key={uuidv4()}
              data={image}
              onClick={e => handleSlideChange(image)}
            />
          ))}
        </ColumnPreview>
        <SampleNextArrow icon="chevron-up" color="#BC9B6B" size="24" className={isMobile && "mobile"}/>
      </Column>
      <Image data={currentImage} className={isMobile && "mobile"}/>
    </GridPreviewImage>
  )
}
