import { AppActionTypes } from "../../store/actionTypes"
import {
  CLEAR_ANCHOR_LISTITEM,
  CLEAR_ANCHOR_LISTITEM_ALL,
  SET_ANCHOR_LISTITEM,
  UPDATE_ANCHOR_LISTITEM,
} from "./anchorListItem.actionTypes"

export function setAnchorListItem(itemAnchor: any): AppActionTypes {
  return {
    type: SET_ANCHOR_LISTITEM,
    itemAnchor,
  }
}

export function updateAnchorListItem(
  after: string,
  numberOfItem: number,
  products: any,
  url: string
): AppActionTypes {
  return {
    type: UPDATE_ANCHOR_LISTITEM,
    after,
    numberOfItem,
    products,
    url
  }
}

export function clearAnchorListItem(): AppActionTypes {
  return {
    type: CLEAR_ANCHOR_LISTITEM,
  }
}
export function clearAnchorAll(): AppActionTypes {
  return {
    type: CLEAR_ANCHOR_LISTITEM_ALL,
  }
}
