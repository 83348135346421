import styled from "styled-components"
import { CommonParagraph } from "../../../../common/Texts"
import { Link as LinkBase } from "../../../../common/Link"
import { ImageWithLinkOrNot } from "../../../../common/ImageWithLinkOrNot"

export const Name = styled(CommonParagraph)`
  text-align: center;
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 0.2em;
`

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 20px 10px;
  &.mobile {
    padding: 0;
    width:50%;
  }
`

export const Link = styled(LinkBase)`
  flex-grow: 1;
  align-items: flex-start;
  &.mobile{
    flex-grow: 0;
    width:60%;
    max-width: 350px;
    max-height: 350px;
  }
`

export const Image = styled(ImageWithLinkOrNot)`
  margin-top:1em;
  height:100%;
  width:100%;
  &.mobile{
    padding-top:1em;
  }
`

export const WrapperDetails = styled.div`
height: 75%;
width: 75%;
display:flex;
flex-direction:column;
align-items:center;
&.mobile{
  height: 100%;
  width: 100%;
}
`

export const Brand = styled(Name)`
  text-transform: uppercase;
  font-size: 1.3em;
  margin-bottom: 0.5em;
  color: #4a4a4a;
`

export const Price = styled.div`
  height: 50%;
  font-size: ${({ theme }) => theme.typography.h1.fontSize};
  display: flex;
  align-items: center;
`