import React from "react"
import { Variant } from "../../../../entities/cart/cart.types"
import { ProductSelectionView } from "./ProductSelection.view"


interface Props {
    brandName: string
    brandLogo: any
    productName: string
    data: Record<string, any>
    variant: Variant
    setVariant: Function
    className?: string
}

export function ProductSelectionContainer(props: Props) {
    return <ProductSelectionView {...props} />
}
