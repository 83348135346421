import styled from "styled-components"
import { CommonSubtitle } from "../../../common/Texts"

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2em 0;
  &.mobile {
    margin: auto;
  }
`

export const Title = styled(CommonSubtitle)`
  font: normal normal 600 30px/22px Cormorant Garamond;
  text-align: left;
  padding: 0;
  &.mobile {
    font-size: 2em;
  }
`

export const Paragraph = styled.div`
  font-family: ${props => props.theme.typography.body.fontFamily};
  font-weight: ${props => props.theme.typography.body.fontWeight};
  font-size: 0.9rem;
  line-height: ${props => props.theme.typography.body.lineHeight};
  letter-spacing: ${props => props.theme.typography.body.letterSpacing};
  color: ${props => props.theme.typography.color};
  margin: 0 0 15px 0;

`
