import React from "react"
import {
  Wrapper,
  Row,
  Text,
  Slider,
  Thumbnail,
  Column,
  Flex,
  TextThumbnail,
  Card,
  Line,
} from "./Composable.style"
import { useQuery } from "@apollo/client"
import { GET_PRODUCTS_MINIMAL_INFO_FROM_SKUS } from "./Composable.queries"
import { getMetadata, getProductUrl } from "../../../../../utils"
import { useDetectDevice } from "../../../../../lib/useDetectDevice"
import { Spinner } from "../../../../common/Spinner"
import { getPricingNode } from "../../../../common/ProductListItem/ProductListItem.view"

interface Props {
  skus: string[]
  className?: string
}

const getDistinctProducts = data => {
  let res = {}
  data.productVariants.edges.map(item => {
    let product = item.node.product
    res[product.slug] = product
  })
  return res
}

export function ComposableView({ skus, className }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")

  if (!skus) {
    return null
  }

  const { loading, error, data } = useQuery(
    GET_PRODUCTS_MINIMAL_INFO_FROM_SKUS,
    {
      variables: { skus: skus },
    }
  )

  if (loading) return <Spinner />
  if (error) return <div>Error loading Composable</div>

  let otherProducts = getDistinctProducts(data)

  var productsInfos: any = []

  Object.keys(otherProducts).map(key => {
    let product = otherProducts[key]
    productsInfos.push({
      Image: {
        url: product.thumbnail?.url,
        name: product.name,
      },
      Link: getProductUrl(product),
      pricing: product.pricing,
    })
  })

  return (
    <Wrapper className={classNames}>
      <Text>Autres produits de la parure</Text>
      {/* <Row className={classNames}>
        <Slider
          data={productsInfos}
          numberOfCards={4}
          size={220}
          objectFit={"contain"}
          className={classNames}
        />
      </Row> */}
      <Flex className={classNames}>
        {productsInfos.map(productInfo => (
          <Column className={classNames}>
            <Thumbnail className={classNames} data={productInfo} />
            <Card className={classNames}>
              <TextThumbnail className={classNames}>
                {productInfo.Image.name}
              </TextThumbnail>
              {/* </Card>
            <Card className={classNames}> */}
              <Line />
              {getPricingNode(productInfo.pricing)}
            </Card>
          </Column>
        ))}
      </Flex>
    </Wrapper>
  )
}
