import styled from "styled-components"
import { Button as ButtonBase } from "../Button/Button.style"
import { Icon as IconBase } from "../Icon"
import { Popin as PopinBase } from "./../Popin"
import {
  CommonSubtitle as CommonSubtitleBase,
  CommonTitle as CommonTitleBase,
} from "./../Texts"

export const Wrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Popin = styled(PopinBase)`
  position: fixed;
  left: 0;
  top: 0;
  overflow: auto;
`

export const CommonTitle = styled(CommonTitleBase)``

export const CommonSubtitle = styled(CommonSubtitleBase)`
  font-size: 1em;
  font-weight: normal;
  &.mobile {
    padding: 0em 3em;
  }
`

export const Button = styled(ButtonBase)`
  cursor: pointer;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 0.75rem;
  padding: 0 0.5rem;
  &.mobile{
    padding: 0;
  }
`

export const SelectChoice = styled.div`
  width: 80%;
`

export const BtnSelectInfo = styled.button`
  background-color: white;
  border: none;
  overflow: none;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
  &.active {
    border-bottom: 4px solid ${({ theme }) => theme.palette.primary.main};
  }
  &.mobile {
    font-size: 0.75em;
  }
`

export const SelectProducts = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
`
