import { useStaticQuery, graphql } from "gatsby"
import { gql } from "@apollo/client"

export const useMarquesImageQuery = () => {
  const data = useStaticQuery(
    graphql`
      query useMarquesImageQuery {
        allStrapiGmPagesMarque {
          edges {
            node {
            NomMarque
            Logo {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 300
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              alternativeText
            }
          }
        }
      } 
      }
    `
  )
  return data
}

export const GET_COMPOSITION_SKUS = gql`
  query getCompositionSkus($slug: String) {
    product(slug: $slug) {     
      metadata {
        key
        value
      }
    }
  }
`

