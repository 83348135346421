import React, { useState, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { useDispatch } from "react-redux"
import {
  Wrapper,
  Row,
  Text,
  TextRow,
  Button,
  RowComposition,
  Composition,
  TextProductPrice,
  TextTotalPrice,
  Separation,
  ProductFromSet,
  Top,
  Top1,
  Bottom,
  BrandRow,
  Header,
  ImageLogo,
  ProductRow,
  Exp,
  Size,
} from "./ProductSet.style"
import { RowDC, Link } from "../ProductSelection/ProductSelection.style"
import { Icon } from "../../../common/Icon"
import { GET_PRODUCTS_AND_VARIANTS_BY_SKUS } from "./ProductSet.queries"
import { Article } from "./Article"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { SizeGuide } from "./../../../common/SizeGuide"
import { Spinner } from "../../../common/Spinner"
import { setHeaderStatus } from "../../../../entities/headerStatus/headerStatus.actions"
import { fixPrice } from "../../../../helper/fixedPrice"
import { v4 as uuidv4 } from "uuid"
import { navigate } from "gatsby"
import slugify from "slugify"
import { useCartWithTotal } from "../../../../hooks/useCartWithTotal"


const getDistinctProducts = data => {
  let res = {}
  data?.productVariants.edges.map(item => {
    let product = item.node.product
    res[product.slug] = product
  })
  return res
}

const getTotalPrice = articles => {
  var amount = 0
  var currency = ""
  Object.keys(articles).forEach(key => {
    var variant = articles[key].product.variant
    var quantity = articles[key].quantity
    amount = amount + variant.pricing.price.gross.amount * quantity
    currency = variant.pricing.price.gross.currency
  })
  return { amount: amount, currency: currency }
}

const getShippingDelay = (data) => {
  const res = data?.productVariants?.edges[0]?.node?.product?.metadata
  let min = res?.filter(x => x.key == "min_shipping")[0]?.value || "7"
  let max = res?.filter(x => x.key == "max_shipping")[0]?.value || "14"
  return { min: min, max: max }
}

const initializeArticles = (productsIntoModel) => {
  let res = {}
  Object.keys(productsIntoModel).map(slug => {
    res[slug] = {
      product: {
        name: productsIntoModel[slug].name,
        slug: productsIntoModel[slug].slug,
        variant: productsIntoModel[slug].defaultVariant,
      }, quantity: 0
    }
  })
  return res
}

interface Props {
  skus: any
  brandName: any
  productName: any
  brandLogo: any
  className?: string
}

export function ProductSetView({
  skus,
  brandName,
  productName,
  brandLogo,
  className,
}: Props) {
  if (!skus) return null

  const { loading, error, data } = useQuery(GET_PRODUCTS_AND_VARIANTS_BY_SKUS, { variables: { skus: skus } })
  const dispatch = useDispatch()
  const { addItem, addItems } = useCartWithTotal()
  const isMobile = useDetectDevice()
  const [productsIntoModel, setProductsIntoModel] = useState({})
  const [articles, setArticles] = useState({})
  const [totalPrice, setTotalPrice] = useState({})
  let classNames = className ? className : ""
  if (isMobile) classNames = classNames + " mobile"

  useEffect(() => {
    if (data) {
      setProductsIntoModel(getDistinctProducts(data))
    }
  }, [data])

  useEffect(() => {
    if (productsIntoModel) {
      setArticles(initializeArticles(productsIntoModel))
    }
  }, [productsIntoModel])

  useEffect(() => {
    if (articles) {
      setTotalPrice(getTotalPrice(articles))
    }
  }, [articles])


  const updateVariant = (slug, article) => {
    let res = JSON.parse(JSON.stringify(articles))
    res[slug].product = article
    setArticles(res)
  }

  const updateQuantity = (slug, quantity) => {
    let res = JSON.parse(JSON.stringify(articles))
    res[slug].quantity = quantity
    setArticles(res)
  }

  const handleCompose = () => {
    addItems(Object.values(articles).map(item=>({variantId:item.product?.variant?.id, quantity:item.quantity}))).then(() => dispatch(setHeaderStatus({ cartOpened: true })))
    setArticles(initializeArticles(productsIntoModel))
  }
  //   addItems(Object.values(articles)).then(() => dispatch(setHeaderStatus({ cartOpened: true })))
  // }

  if (loading) return <Spinner />
  if (error) return <div>Error loading ProductSet</div>
  const slugBrand = `/${slugify(brandName, { lower: true })}`

  return (
    <Wrapper className={classNames}>
      <Separation>
        <Header className={classNames}>
          {/* <ImageLogo data={brandLogo} onClick={() => navigate(slugBrand)} /> */}
          <BrandRow className={classNames}>{brandName}</BrandRow>
          <ProductRow className={classNames}>
            {productName}
          </ProductRow>
        </Header>
        <Top>
          {<TextProductPrice>
            {totalPrice.amount > 0 && `${fixPrice(totalPrice.amount)} ${totalPrice.currency}`}
          </TextProductPrice>}
          <Top1>
            <Exp className={classNames}>
              Expédition sous {getShippingDelay(data).min}/{getShippingDelay(data).max} jours ouvrés
            </Exp>
            <Size>
              <Text>Guide des tailles </Text>
              <SizeGuide />
            </Size>
          </Top1>
        </Top>
        <hr></hr>
        <Row>
          <TextRow>Produits de la parure</TextRow>
          <TextRow>Taille / Coloris</TextRow>
          <TextRow>Quantité</TextRow>
        </Row>
        <hr></hr>
      </Separation>
      <ProductFromSet>
        {Object.keys(productsIntoModel).map(key => {
          let item = productsIntoModel[key]
          if (!Object.keys(articles).length) return null
          let selection = articles[key]
          return (
            <Article
              key={uuidv4()}
              className={classNames}
              product={item}
              selectedVariant={selection.product.variant}
              quantity={selection.quantity}
              updateVariant={updateVariant}
              updateQuantity={updateQuantity}
            />
          )
        })}
      </ProductFromSet>
      <Bottom className={classNames}>
        <RowComposition className={classNames}>
          <Button onClick={handleCompose} className={classNames}>
            Ajouter au panier
          </Button>
          <Composition className={classNames}>
            <Text>Total</Text>
            <TextTotalPrice>
              {fixPrice(totalPrice.amount)} {totalPrice.currency}
            </TextTotalPrice>
          </Composition>
        </RowComposition>
      </Bottom>
    </Wrapper>
  )
}
