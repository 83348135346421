import React from "react"
import {
  Wrapper,
  Link,
  Image,
  Brand,
  Name,
  Price,
  WrapperDetails,
} from "./RelatedProduct.style"
import { useQuery } from "@apollo/client"
import { GET_PRODUCT_VARIANT } from "./RelatedProduct.queries"
import { getMetadata, getProductUrl } from "../../../../../utils"
import { useDetectDevice } from "../../../../../lib/useDetectDevice"
import { Spinner } from "../../../../common/Spinner"
interface Props {
  sku: String
  brandsLogo: any
  className?: string
  metadata: Record<string, string>
}

export function RelatedProductView({ sku, brandsLogo, className, metadata }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")
  const { loading, error, data } = useQuery(GET_PRODUCT_VARIANT, {
    variables: { sku: sku },
  })

  if (loading) return <Spinner />
  if (error) return <div>Error</div>

  var metadata = getMetadata(data.productVariant?.product?.metadata)
  if(data.productVariant?.product!=null){
    return (
      <Wrapper className={classNames}>
        <Link link={getProductUrl(data.productVariant?.product)}>
        <WrapperDetails className={classNames}>
              <Image className={classNames} data={data.productVariant.product.thumbnail} />
              <Brand> {metadata["zoho_brand"]} </Brand>
              <Name>{data.productVariant.product.name}</Name>
              <Price>
                {data.productVariant.product.priceRange?.start?.amount}
                {data.productVariant.product.priceRange?.start?.currency}
              </Price> 
            </WrapperDetails>
        </Link>
      </Wrapper>
    )
  }
  else{
    return(null)
  }
}
