import React from "react"

import { Wrapper, TitleWrapper, SuggestArea } from "./RelatedProducts.style"
import { TextOverText } from "../../../common/TextOverText"
import { v4 as uuidv4 } from "uuid"
import { RelatedProduct } from "./RelatedProduct"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
interface Props {
  skus?: String[]
  brandsLogo: Record<string, any>[]
  className?: string
}

export function RelatedProductsView({ skus, brandsLogo, className }: Props) {
  if (!Array.isArray(skus)) {
    return <></>
  }
  const isMobile = useDetectDevice()

  return (
    <Wrapper className={className}>
      <TitleWrapper>
        <TextOverText small="Nous vous suggérons ceci" large="Suggestions" />
      </TitleWrapper>
      <SuggestArea className={isMobile && "mobile"}>
        {skus.map(sku => (
          <RelatedProduct key={uuidv4()} sku={sku} brandsLogo={brandsLogo} />
        ))}
      </SuggestArea>
    </Wrapper>
  )
}
