import { gql } from "@apollo/client"

export const GET_PRODUCT_VARIANT = gql`
  query getProduct($sku: String!) {
    productVariant(sku: $sku) {
      product {
        slug
        name
        seoTitle
        seoDescription
        thumbnail(size: 512) {
          url
          alt
        }
        media {
          url
          alt
        }
        metadata {
          key
          value
        }
      }
    }
  }
`
