import styled from "styled-components"
import { Link as LinkBase } from "./../../common/Link"

export const BreadcrumbsSeparator = styled.div`
  margin-left: 2px;
  margin-right: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20em;
`

export const Wrapper = styled.div`
  padding: 0.75em 0 1em 1em;
  display: flex;
  flex-wrap: wrap;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  color: ${({ theme }) => theme.palette.primary.main};
  a:hover {
    text-decoration: none;
  }
  border-bottom: 1px solid #D8D8D8;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const LinkPreviousPage = styled(LinkBase)`
  padding: 10px 20px;
  border-radius: 50px;
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  background: #213544 0% 0% no-repeat padding-box;
  color: white;

  font-family: ${({ theme }) => theme.typography.button.fontFamily};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  font-size: ${({ theme }) => theme.typography.button.fontSize};
  line-height: ${({ theme }) => theme.typography.button.lineHeight};

  width: fit-content;
  margin-left: auto;
  margin-right: auto;


  &.mobile {
    padding: 15px 25px;
    font-size: 15px;
    margin-bottom: 10%;
  }
`
