import styled from "styled-components"
import { ImageWithLinkOrNot } from "../ImageWithLinkOrNot"
import { CommonParagraph } from "./../Texts"

export const Column = styled.div`
  width: 100%;
  max-width: 250px;
  padding-left: 35px;
  gap: 25px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  &.mobile {
    display: none;
  }
`

export const Info = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  text-align: left;
  &.mobile {
    width: 100%;
  }
`

export const Text = styled(CommonParagraph)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin: auto 0;
  &.mobile {
    font-size: 0.8rem;
    padding-top:1em;
    padding-bottom: 2em;
  }
`

export const Img = styled(ImageWithLinkOrNot)`
  height: 63px;
  width: 63px;
  min-width: 60px;
`

export const Flex = styled.div`
display: flex;
gap: 50px;
margin: auto;
padding: 50px 0;
justify-content: center;

`