import React from "react"
import {
  Wrapper,
  TitleWrapper,
  Title,
  Paragraph,
} from "./ProductDescription.style"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { buildHtmlFromString } from "../../../../lib/buildHtmlFromString"
import { isEditorjs, convertEditorjsJsonToHtml } from "./../../../../lib/util"
import Draft, { convertFromRaw } from "draft-js"
const { stateToHTML } = require("draft-js-export-html")

interface Props {
  id?: string
  data: string
  className?: string
}

export function ProductDescriptionView({ id = "", data, className }: Props) {
  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")
  let html = ""
  try {
    if (isEditorjs(data)) {
      html = convertEditorjsJsonToHtml(data)
    } else {
      let obj = JSON.parse(data)
      const isHtmlString = obj.blocks.some(block => {
        const { data } = block
        return data != null
      })
      if (isHtmlString) {
        const result = obj.blocks.map(({ data }) => {
          return data?.text
        })
        html = `${result.join("<br>").replace(/"/g, "")}`
      } else {
        obj.inlineStyleRanges = obj.inlineStyleRanges || []
        obj.entityRanges = obj.entityRanges || []
        obj.entityMap = obj.entityMap || {}
        const result = convertFromRaw(obj)
        html = stateToHTML(result)
      }
    }
  } catch (error) {
    console.warn("Can't format rich text", error)
  }
  return (
    <Wrapper id={id} className={classNames}>
      <Title className={isMobile && "mobile"}>Description du produit</Title>
      {!isEditorjs(data) && <Paragraph>{buildHtmlFromString(html)}</Paragraph>}
      {isEditorjs(data) && (
        <Paragraph dangerouslySetInnerHTML={{ __html: html }} />
      )}
    </Wrapper>
  )
}
