import React, { useState, useEffect } from "react"
import {
  Wrapper,
  SelectorsWrapper,
  Top,
  Promotion,
  Price,
  GridChoiceProduct,
  GridChoice,
  Row,
  Text,
  TextError,
  TextExp,
  Button,
  Amount,
  Counter,
  Select,
  Separation,
  ButtonArea,
  Header,
  ImageLogo,
  ProductRow,
  PriceArea,
  Paragraph,
  TextSizeGuide,
  TextCounter,
  Alerte,
  QuantityMaxMessage,
  WrapperComposable,
} from "./ProductSelection.style"
import { useQuery } from "@apollo/client"
import { useDispatch } from "react-redux"
import { Variant } from "../../../../entities/cart/cart.types"
import { SizeGuide } from "./../../../common/SizeGuide"
import { navigate } from "gatsby"
import { Spinner } from "./../../../common/Spinner"
import { getMetadata } from "../../../../utils/metadata"
import { useDetectDevice } from "../../../../lib/useDetectDevice"
import { setHeaderStatus } from "../../../../entities/headerStatus/headerStatus.actions"
import { isDomAvailable } from "./../../../../lib/index"
import { GET_MODEL_METADATA_FROM_SLUG } from "./ProductSelection.queries"
import { fixPrice } from "../../../../helper/fixedPrice"
import { v4 as uuidv4 } from "uuid"
import slugify from "slugify"
import { useCartWithTotal } from "../../../../hooks/useCartWithTotal"
import { buildHtmlFromString } from "../../../../lib/buildHtmlFromString"
import { convertEditorjsJsonToHtml, isEditorjs } from "../../../../lib/util"
import { convertFromRaw } from "draft-js"
import { stateToHTML } from "draft-js-export-html"

interface Props {
  brandName: string
  brandLogo: any
  productName: string
  data: Record<string, any>
  variant: Variant
  setVariant: Function
  className?: string
}

const getDefaultSelectors = variant => {
  var res = {}
  variant.attributes.map(item => {
    var name = item?.attribute?.name
    var value = item?.values[0]?.name
    if (value) {
      res[name] = value
    }
  })
  return res
}

export function ProductSelectionView({
  brandName,
  productName,
  brandLogo,
  data,
  variant,
  setVariant,
  className,
}: Props) {
  const [totalPrice, setTotalPrice] = useState(0)
  const [quantity, setQuantity] = useState(1)
  const [promotionPrice, setPromotionPrice] = useState(0)
  const dispatch = useDispatch()
  const meta = getMetadata(data.metadata)
  const [activeSelectors, setActiveSelectors] = useState(
    getDefaultSelectors(variant)
  )
  const { addItem } = useCartWithTotal()
  const [isVariantUnvalable, setIsVariantUnvalable] = useState(false)

  useEffect(() => {
    setTotalPrice(
      (quantity * 100 * (variant.pricing.price.gross.amount * 100)) / 10000
    )
    var reduc = (100 * variant.pricing.price.gross.amount) / 100
    if (variant.pricing.onSale) {
      reduc =
        (variant.pricing.price.gross.amount * 100 +
          variant.pricing.discount.gross.amount * 100) /
        100
    }
    var resultToFix = (quantity * 100 * (reduc * 100)) / 10000
    setPromotionPrice(fixPrice(resultToFix))
  }, [quantity, variant])

  useEffect(() => {
    const result = data.variants.map(variantItem => {
      var isTheOne = true
      variantItem.attributes.map(item => {
        var name = item?.attribute?.name || ""
        var value = item?.values[0]?.name || ""
        if (activeSelectors.hasOwnProperty(name)) {
          if (activeSelectors[name] != value) {
            isTheOne = false
          }
        }
      })
      if (isTheOne) {
        setVariant(variantItem)
      }
      return isTheOne
    })
    const finded = result.some(x => x == true)
    finded ? setIsVariantUnvalable(false) : setIsVariantUnvalable(true)
  }, [activeSelectors])

  const handleVariantChange = (e: any) => {
    let name = e.target.name
    let value = e.target.value
    let res = { ...activeSelectors }
    res[name] = value
    setActiveSelectors(res)
  }

  const handleButtonClick = (e: any) => {
    var product = {
      name: data.name,
      slug: data.slug,
      variant: variant,
    }
    if (product.variant.media.length == 0) {
      product.variant.media = data.media
    }
    addItem(product.variant.id, quantity)
    dispatch(setHeaderStatus({ cartOpened: true }))
  }

  const navigateToComposition = () => {
    if (isDomAvailable()) {
      let current = window.location.pathname.split("/")
      if (current[current.length - 1] == "") {
        current.pop()
      }
      current.pop()
      current.push(meta["model_id"])
      let path = current.join("/")
      navigate(path)
    }
  }

  let quantityAvailable = variant.quantityAvailable
  let isOnAlert = false
  let manyAvailable = false
  if (quantityAvailable < 10) {
    isOnAlert = true
    if (quantityAvailable > 1) {
      manyAvailable = true
    }
  }

  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")
  // TODO : enlever les meta model id
  let metaModel = []
  // If the product is part of a model
  if (meta["model_id"] && meta["is_model"] == "false") {
    const {
      loading: loadingModel,
      error: errorModel,
      data: dataModel,
    } = useQuery(GET_MODEL_METADATA_FROM_SLUG, {
      variables: { slug: meta["model_id"] },
    })
    if (loadingModel) return <Spinner />
    if (errorModel) return <div>Error loading Model</div>
    metaModel = getMetadata(dataModel.product.metadata)
  }

  const constructAttributesFromData = data => {
    var res = {}
    data.map(item => {
      var variantId = item.id
      item.attributes.map(attributesNode => {
        if (attributesNode.attribute) {
          var attrName = attributesNode.attribute.name
          if (!res[attrName]) {
            res[attrName] = {}
          }
          attributesNode.values.map(valuesNode => {
            let valueName = valuesNode.name
            if (!res[attrName][valueName]) {
              res[attrName][valueName] = []
            }
            res[attrName][valueName].push(variantId)
          })
        }
      })
    })
    return res
  }

  const getVariantSelectors = data => {
    let attributes = constructAttributesFromData(data)
    return Object.keys(attributes).map(name => {
      return (
        <SelectorsWrapper className={isMobile && "mobile"} key={uuidv4()}>
          {name === "Taille" && (
            <TextSizeGuide className={isMobile && "mobile"}>
              <Text className={isMobile && "mobile"}>{name}</Text>
              <SizeGuide />
            </TextSizeGuide>
          )}
          {name !== "Taille" && <Text>{name}</Text>}
          <Select
            name={name}
            defaultValue={activeSelectors[name]}
            className={isMobile && "mobile"}
            onChange={handleVariantChange}
          >
            {Object.keys(attributes[name]).map(value => {
              return (
                <option key={uuidv4()} value={value}>
                  {value}
                </option>
              )
            })}
          </Select>
        </SelectorsWrapper>
      )
    })
  }
  const slugBrand = `/${slugify((slugify(brandName.replace('’', ""))).replace('\'',""), { lower: true })}`

  let html = ""
  try {
    if (isEditorjs(data.description)) {
      html =
        convertEditorjsJsonToHtml(data.description).substring(0, 200) + "..."
    } else {
      let obj = JSON.parse(data.description)
      const isHtmlString = obj.blocks.some(block => {
        const { data } = block
        return data != null
      })
      if (isHtmlString) {
        const result = obj.blocks.map(({ data }) => {
          return data?.text
        })
        html = `${result.join("<br>").replace(/"/g, "")}`
      } else {
        obj.inlineStyleRanges = obj.inlineStyleRanges || []
        obj.entityRanges = obj.entityRanges || []
        obj.entityMap = obj.entityMap || {}
        const result = convertFromRaw(obj)
        html = stateToHTML(result)
      }
    }
  } catch (error) {
    console.warn("Can't format rich text", error)
  }

  return (
    <Wrapper className={classNames}>
      <Separation>
        <Header className={isMobile && "mobile"}>
          <ImageLogo data={brandLogo} onClick={() => navigate(slugBrand)} />
          <ProductRow className={isMobile && "mobile"}>
            <h1>{productName}</h1>
          </ProductRow>
          {!isEditorjs(data.description) && (
            <Paragraph>{buildHtmlFromString(html.substring(1, 200))}</Paragraph>
          )}
          {isEditorjs(data.description) && (
            <Paragraph dangerouslySetInnerHTML={{ __html: html }} />
          )}
        </Header>
        <Top>
          <PriceArea>
            <Price>{fixPrice(totalPrice)} €</Price>
            {variant.pricing.onSale && (
              <Promotion>{fixPrice(promotionPrice)} €</Promotion>
            )}
          </PriceArea>
        </Top>
        {data.metadata.filter(x => x.key == "min_shipping")[0]?.value ? (
            <TextExp className={isMobile && "mobile"}>
              Expédition sous{" "}
              {data.metadata?.filter(x => x.key == "min_shipping")[0].value}/
              {data.metadata?.filter(x => x.key == "max_shipping")[0].value}{" "}
              jours ouvrés
            </TextExp>
          ) : (
            <TextExp className={isMobile && "mobile"}>
              {" "}
              Expédition sous 7/14 jours ouvrés
            </TextExp>
          )}
        
        {data.productType.variantAttributes && (
          <GridChoiceProduct className={isMobile && "mobile"}>
            <GridChoice className={isMobile && "mobile"}>
              {getVariantSelectors(data.variants)}
            </GridChoice>
          </GridChoiceProduct>
        )}
      </Separation>
      <CompositionPart
        isMobile={isMobile}
        setQuantity={(count: number) => setQuantity(count)}
        handleButtonClick={handleButtonClick}
        navigateToComposition={navigateToComposition}
        modelSlug={meta["model_id"]}
        isVariantUnvalable={isVariantUnvalable}
        quantityMax={quantityAvailable}
      ></CompositionPart>
      {isOnAlert && (
        <>
          {manyAvailable &&
            <Alerte>
            Plus que {quantityAvailable} articles disponibles !
          </Alerte>
          }
          {!manyAvailable &&
            <Alerte>
            Plus que {quantityAvailable} article disponible !
          </Alerte>
          }
        </>
      )}    
    </Wrapper>
  )
}

const CompositionPart = ({
  isMobile,
  setQuantity,
  handleButtonClick,
  navigateToComposition,
  modelSlug,
  isVariantUnvalable,
  quantityMax
}) => {
  const [stateReset, setStateReset] = useState(false)
  const [noMoreAvailable, setNoMoreAvailable] = useState(false)

  const resetCounter = () => {
    setStateReset(true)
    handleButtonClick()
  }
  
  const onUpdateQuantity = (quantity: number) => {
    setStateReset(false)
    
    var diff = quantity - quantityMax

    if (diff > 0) {
      quantity=quantityMax
      setQuantity(quantity)
      setNoMoreAvailable(true)
    }
    else{
      setQuantity(quantity)
      setNoMoreAvailable(false)
    }
  }

  return (
    <WrapperComposable className={isMobile && "mobile"}>
      <Row className={isMobile && "mobile"}>
        <Amount className={isMobile && "mobile"}>
          {isMobile && <TextCounter>Qté</TextCounter>}
          {!isMobile && <TextCounter>Quantité</TextCounter>}
          <Counter onChange={onUpdateQuantity} value={stateReset ? 0 : null} />
        </Amount>
        <ButtonArea>
          {isVariantUnvalable && <TextError>Option non disponible</TextError>}
          {!isVariantUnvalable && (
            <Button onClick={resetCounter}>Ajouter au panier</Button>
            )}
        </ButtonArea>
      </Row>
      {noMoreAvailable && <QuantityMaxMessage>{quantityMax} maximum</QuantityMaxMessage>}
    </WrapperComposable>
  )
}
