import React, { useState } from "react"

import {
  Wrapper,
  Tab,
  Column,
  Row,
  Text,
  TabTitle,
  CommonSubtitle,
} from "./../SizeGuideProduct.style"

interface Props {
  className?: string
}

export function SizeCouvreLitView({ className }: Props) {
  const [active, setActive] = useState("M90")
  const toggleActive = (id: string) => {
    active == id ? setActive("") : setActive(id)
  }

  return (
    <Wrapper className={className}>
      <CommonSubtitle>
        CHOISIR LA BONNE TAILLE POUR SON COUVRE LIT
      </CommonSubtitle>
      <TabTitle onClick={() => toggleActive("M90")}>
        MATELAS 1 personne 90x190 ou 90x200
      </TabTitle>
      {active == "M90" && (
        <Tab>
          <Column>
            <Row>Couvre Lit</Row>
            <Row>
              <Text>180 x 250 cm</Text>
              <Text>ou</Text>
              <Text>190 x 270 cm</Text>
            </Row>
          </Column>
        </Tab>
      )}
      <TabTitle onClick={() => toggleActive("M140")}>
        MATELAS 2 personnes 140x190 ou 140x200
      </TabTitle>
      {active == "M140" && (
        <Tab>
          <Column>
            <Row>Couvre Lit</Row>
            <Row>
              <Text>230 x 250 cm</Text>
              <Text>ou</Text>
              <Text>235 x 270 cm</Text>
            </Row>
          </Column>
        </Tab>
      )}
      <TabTitle onClick={() => toggleActive("M160")}>
        MATELAS 2 personnes 160x200
      </TabTitle>
      {active == "M160" && (
        <Tab>
          <Column>
            <Row>Couvre Lit</Row>
            <Row>
              <Text>250 x 270 cm</Text>
              <Text>ou</Text>
              <Text>270 x 270 cm</Text>
            </Row>
          </Column>
        </Tab>
      )}
      <TabTitle onClick={() => toggleActive("M180")}>
        MATELAS 2 personnes 180x200
      </TabTitle>
      {active == "M180" && (
        <Tab>
          <Column>
            <Row>Couvre Lit</Row>
            <Row>
              <Text>270 x 270 cm</Text>
              <Text>ou</Text>
              <Text>280 x 270 cm</Text>
            </Row>
          </Column>
        </Tab>
      )}
      <TabTitle onClick={() => toggleActive("M200")}>
        MATELAS 2 personnes 200x200
      </TabTitle>
      {active == "M200" && (
        <Tab>
          <Column>
            <Row>Couvre Lit</Row>
            <Row>300 x 270 cm</Row>
          </Column>
        </Tab>
      )}
    </Wrapper>
  )
}
