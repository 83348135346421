import { gql } from "@apollo/client"

export const GET_PRODUCTS_MINIMAL_INFO_FROM_SKUS = gql`
  query getProductsMinimalInfos($skus: [String]) {
    productVariants(filter: { sku: $skus }, first: 10000) {
      edges {
        node {
          product {
            slug
            name
            seoTitle
            seoDescription
            metadata {
              key
              value
            }
            thumbnail(size: 512) {
              url
              alt
            }
            media{
              url
              alt
            }
            pricing {
            onSale
            priceRangeUndiscounted {
              start {
                gross {
                  amount
                }
              }
            }
            priceRange {
              start {
                gross {
                  amount
                }
              }
            }
          }
          }
        }
      }
    }
  }
`
