import { useLazyQuery } from "@apollo/client"
import React, { useContext, useLayoutEffect, useState, useEffect } from "react"
import { BreadcrumbsBoxView } from "./BreadcrumbsBox.view"
import { GET_CATEGORY_ATTACH_FROM_COLLECTION } from "./BreadcrumbsBox.query"

import { LinkPreviousPage } from "./BreadcrumbsBox.style"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { ColumnTextInfo } from "../../specific/PageCheckout/ProductList/ProductRow/ProductRow.style"
import { isDomAvailable } from "../../../lib"

interface props {
    category: { name?: string, level: number, slug?: string, parent?: { name?: string, slug?: string, parent?: { name?: string, slug?: string, } } },
    productName?: string
}
// la recuperation de la category lier à une collection avec strapi (si besoin ) entraine l'appel d'une query apollo pour recuperer la category lier sur saleor
export const useBreadcrumbsBox = ({ category, productName }: props) => {

    const [context, setContext] = useState("")

    const BreadCrumbs = React.useCallback(() => {
        if (!category) return (<></>)

        return (
            <ContainerCore category={category} productName={productName} setContext={setContext} />
        )
    }, [category])


    const NavigateBackButton = React.useCallback(({ className }) => {
        const isMobile = useDetectDevice()
        let labelButton = ""


        if (context.level == 1) {
            labelButton = "Tout l'univers"
        }
        else if (context.level == 2) {
            labelButton = "Toute la catégorie"
        }

        if (!context?.parentSlug) return (<></>)
        if (isDomAvailable()) {
            let current = window.location.pathname.split("/")
            if (current[1] == "p") {
                return (
                    <LinkPreviousPage link={"/" + context?.categorySlug} className={isMobile ? className + " mobile" : className} > {labelButton + " " + context?.categoryName} </LinkPreviousPage >
                )
            }
            else{
                if(current[1]==context?.categorySlug){
                    return(
                    <LinkPreviousPage link={"/" + context?.parentSlug} className={isMobile ? className + " mobile" : className} > {labelButton + " " + context?.parentName} </LinkPreviousPage >
                    )
                }
                return (
                    <LinkPreviousPage link={"/" + context?.categorySlug} className={isMobile ? className + " mobile" : className} > {labelButton + " " + context?.categoryName} </LinkPreviousPage >
                )
            }
        }
    }, [context]
    );


    return ([BreadCrumbs, NavigateBackButton])

}




const ContainerCore = ({ category, productName, setContext }: props) => {

    const [currentCategory, setCurrentCategory] = useState(category?.name ? category : null)
    // permet de faire la requete uniquement si besoin 
    const [getCategoryAttach, dynamicGetCategoryAttach]: [
        getCategoryAttach: Function,
        dynamicGetCategoryAttach: { called: boolean; loading: boolean; data: any }
    ] = useLazyQuery(GET_CATEGORY_ATTACH_FROM_COLLECTION, { variables: { categoryId: category?.id } })


    useLayoutEffect(() => {
        if (category && !category?.name && !dynamicGetCategoryAttach.called && !dynamicGetCategoryAttach.loading) {
            getCategoryAttach()
        }
    })

    useEffect(() => {
        if (dynamicGetCategoryAttach.data) {
            setCurrentCategory(dynamicGetCategoryAttach.data.category)
        }

    }, [dynamicGetCategoryAttach])


    if (!currentCategory) return <></>
    return <BreadcrumbsBoxView category={currentCategory} productName={productName} setContext={setContext} />
}

export const NavigationContext = React.createContext({} as Context);


interface Context {
    productName?: string;
    categoryName?: string;
    categorySlug?: string;
    context?: string;
    parentName?: string;
    parentParentName?: string;
    parentParentSlug?: string;
}