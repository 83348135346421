import { gql } from "@apollo/client"

export const GET_PRODUCTS_AND_VARIANTS_BY_SKUS = gql`
  query getProductsAndVariantsBySkus($skus: [String]) {
    productVariants(filter: { sku: $skus }, first: 100) {
      edges {
        node {
          product {
            name
            slug
            metadata {
              key
              value
            }
            thumbnail(size: 512) {
              url
              alt
            }
            media {
              url
              alt
            }
            seoTitle
            seoDescription
            defaultVariant {
              name
              id
              media {
                url
                alt
              }
              attributes {
                attribute {
                  id
                  name
                }
                values {
                  name
                  id
                }
              }
              attributes {
                attribute {
                  id
                  name
                }
                values {
                  name
                  id
                }
              }
              pricing {
                onSale
                discount {
                  tax {
                    amount
                    currency
                  }
                  net {
                    amount
                    currency
                  }
                  gross {
                    amount
                    currency
                  }
                }
                priceUndiscounted {
                  tax {
                    amount
                    currency
                  }
                  net {
                    amount
                    currency
                  }
                  gross {
                    amount
                    currency
                  }
                }
                price {
                  tax {
                    amount
                    currency
                  }
                  net {
                    amount
                    currency
                  }
                  gross {
                    amount
                    currency
                  }
                }
              }
            }
            variants {
              name
              id
              media {
                url
                alt
              }
              attributes {
                attribute {
                  id
                  name
                }
                values {
                  name
                  id
                }
              }
              attributes {
                attribute {
                  id
                  name
                }
                values {
                  name
                  id
                }
              }
              pricing {
                onSale
                discount {
                  tax {
                    amount
                    currency
                  }
                  net {
                    amount
                    currency
                  }
                  gross {
                    amount
                    currency
                  }
                }
                priceUndiscounted {
                  tax {
                    amount
                    currency
                  }
                  net {
                    amount
                    currency
                  }
                  gross {
                    amount
                    currency
                  }
                }
                price {
                  tax {
                    amount
                    currency
                  }
                  net {
                    amount
                    currency
                  }
                  gross {
                    amount
                    currency
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
