import styled from "styled-components"
import { ImageWithLinkOrNot } from "./../../../../common/ImageWithLinkOrNot"
import { Counter } from "../../../../common/Counter"
import { WrapperCounter } from "../../../../common/Counter/Counter.style"
import { CommonParagraph } from "../../../../common/Texts"

export const Wrapper = styled.div`
  width: auto;
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  align-items: center;
  &.mobile {
    grid-template-columns: 1fr;
  }
`

export const Text = styled(CommonParagraph)`
  margin-top: 15px;
  margin-left: 1em;
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
`

export const Image = styled(ImageWithLinkOrNot)`
  width: 75px;
`

export const TextProduct = styled(CommonParagraph)`
  display: flex;
  flex-wrap: wrap;
  text-align: start;
  align-items: center;
  padding-left: 1em;
  margin: 0;

  font: normal normal normal 14px/17px Poppins;
  &.mobile {
    width: auto;
    align-items: center;
  }
`

export const TextPrice = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  margin: 0 0.5em;
  &.mobile {
    grid-row: 2;
    grid-column: 3;
    justify-content: center;
  }
`

export const StyledCounter = styled(Counter)`
  ${WrapperCounter} {
    // border: 1px solid ${({ theme }) => theme.palette.primary.main};
    width: 90px;
  }
  &.mobile {
    grid-column :3;
  }
`

export const Select = styled.select`
  width: 100px;
  height: 3em;
  border-radius: 50px;
  cursor: pointer;
  &.mobile {
    // border: 3px red solid;
  }
`

export const UnitPrice = styled.div``

export const ProductInfos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`

export const SelectProduct = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.mobile {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 0.5em;
  }
  &.isvariantunvalable {
    Select {
      border: 1.5px red solid;
    }
  }
`

export const OldPrice = styled(CommonParagraph)`
  padding-left: 10px;
  opacity: 0.7;
  text-decoration-line: line-through;
  margin: 0px;
  font-style: italic;
`

export const NewPrice = styled(CommonParagraph)`
  padding-left: 10px;
  margin: 0px;
`
